import { parsePrice } from '@flamingo_tech/funkgo/src/utils/price'
import OrderModel from '../../../models/Order/OrderModel'


class CartModel {
  constructor(data) {
    this.id = data.shoppingCartId

    // The sum of all the prices of all the items in the checkout, taxes and discounts included.
    this.totalPrice = parsePrice(data.totalFee || 0)

    // Price of the checkout before shipping, taxes and discounts.
    this.lineItemsSubtotalPrice = parsePrice(data.productFee || 0)

    // shipping fee
    this.shippingFee = parsePrice(data.freight || 0)

    // discounts
    this.discountFee = parsePrice(data.discountFee || 0)

    this.displayDiscountFee = data.displayDiscountFee

    this.displayFreight = data.displayFreight

    this.displayProductFee = data.displayProductFee

    this.displayTotalFee = data.displayTotalFee

    this.addOnsForFreeShippingAmount = parsePrice(data.addOnsForFreeShippingAmount || 0)

    this.displayAddOnsForFreeShippingAmount = data.displayAddOnsForFreeShippingAmount

    this.currencySymbol = data.currencySymbol

    this.currencyCode = data.currencyCode

    this.displayDeductCoinValue = data.displayDeductCoinValue

    this.itemQuantity = data.totalQuantity

    this.lineItems = (data.list || []).map(item => new LineItemModel(item))

    this.couponList = data.couponList || []

    this.appliedCouponId = data.couponId

    this.model = new OrderModel(data) // only for track

    this.totalSaveFee =  parsePrice(data.totalSaveFee || 0)

    this.displayTotalSaveFee = data.displayTotalSaveFee

    this.productTotalFeeAfterDiscount = data.productTotalFeeAfterDiscount

    this.displayProductTotalFeeAfterDiscount = data.displayProductTotalFeeAfterDiscount

    this.productFee = parsePrice(data.productFee || 0)

    this.displayTotalProductSaveFee = data.displayTotalProductSaveFee

    this.addOnInfo = data.addOnInfo

  }
}

class LineItemModel {
  constructor(data) {
    this.id = data.skuId

    this.itemId = data.itemId

    this.handle = data.productHandle

    this.productId = data.productId

    this.quantity = data.quantity

    this.title = data.skuName

    this.variantSku = data.sku

    this.variantTitle = data.skuTitle

    this.variantPrice = parsePrice(data.price)

    this.category = data.categoryName

    this.image = data.mainImage

    this.saleable = data.saleable

    this.inventory = data.inventory

    this.currencyCode = data.currencyCode

    this.displayPrice = data.displayPrice

    this.displayItemTotalFee = data.displayItemTotalFee

    this.selectedFlag = data.selectedFlag

    this.variantMsrp = data.msrp && parsePrice(data.msrp)

    this.displayMsrp = data.displayMsrp

    this.selectedOptions = data.selectedOptions || []

    this.colorSwatch = data.colorSwatch || {}

    this.tags = data.tags || []
    this.promotionActivities = data.promotionActivities
    this.isFlashSale = data.promotionActivities && data.promotionActivities.filter(i => i.name === 'FlashSale').length > 0
    this.canReturn = ((data.promotionActivities || []).filter(i => i.name === 'FlashSale')[0] || {}).canReturn
    this.soldOutPercent = data.soldOutPercent
  }
}


export default CartModel
