import { BaseService } from '@flamingo_tech/funkgo'

import CouponListModel from '../models/Coupon/CouponListModel'

import {
  retryIfTimeout,
} from '../utils/requestUtils'

class LayoutService extends BaseService {

  fetchFeaturedCategories() {
    return retryIfTimeout(
      () => this.get(`/client/api/v2/store-meta/latest_featured_categories`),
    )
  }

  fetchPromotion(handle) {
    return this.get(`/client/api/v2/store-meta/promotion_${handle}`)
  }

  fetchCouponCenter(handle) {
    return this.get(`/client/api/v2/store-meta/coupon_${handle}`).then(
      result => new CouponListModel(result))
  }

  fetchSearchMeta() {
    return retryIfTimeout(
      () => this.get(`/client/api/v2/store-meta/search_meta`, {}, {
        params: {
          lft: 'web'
          // specific link format as web; since if run on app webview, the client-id may be "ios" or "android"
          // , then it will receive the link with app scheme format
        }
      }),
    )
  }

  fetchUserReviews() {
    return this.get(`/client/api/v2/store-meta/user_reviews`)
  }


  handleGlobalPromotionMeta(data) {
    const productQueueFields = ['heroProduct', 'crossSell']

    const { collections = {} } = data

    productQueueFields.forEach(field => {
      const collectionHandle = data[field] && data[field].productQueue && data[field].productQueue.collectionSource && data[field].productQueue.collectionSource.handle

      if (collectionHandle && collections[collectionHandle]) {
        data[field].products = collections[collectionHandle].products.map(product => product.product)
      }
    })

    return data
  }

  fetchCancelOrderQuestionnaire = () => {
    return this.get(`/client/api/v2/store-meta/cancelOrderReason`)
  }

  _fetchGlobalConfig({ targetPlatform } = {}) {
    const PROMOTION_META_KEY = 'global_promotion_meta'
    const SETTING_META_KEY = 'global_setting_meta'
    const REGULAR_COUPON_META_KEY = 'regular_coupon_meta'
    const handle = `${PROMOTION_META_KEY},${SETTING_META_KEY},${REGULAR_COUPON_META_KEY}`

    // add ?spu=1 to fetch product queues
    const params = {
      handles: handle,
      spu: 1
    }

    if (targetPlatform) {
      params._ptm = targetPlatform
    }

    // a workaround to get correct promotion on app env
    return this.get(`/client/api/v2/store-meta`, {
      params
    }).then(data => {
      const globalPromotionMeta = this.handleGlobalPromotionMeta(data[PROMOTION_META_KEY])
      const globalSettingMeta = data[SETTING_META_KEY]
      const regularCouponMeta = data[REGULAR_COUPON_META_KEY]

      return {
        globalPromotionMeta,
        globalSettingMeta,
        regularCouponMeta
      }
    })
 }

 fetchGlobalConfig(payload) {
    return retryIfTimeout(
      () => this._fetchGlobalConfig(payload),
      5
    )
 }

 fetchOrderMeta() {
   return this.get(`/client/api/v2/store-meta/order_meta`)
 }

  getCategoriesMeta = () => {
    const STORE_META_URL = '/client/api/v2/store-meta/favorite_categories'
    return this.get(STORE_META_URL)
  }

  fetchProductRankings = handle => {
    return this.get(`/client/api/v2/store-meta/product_rankings_${handle}`)
  }

  fetchTrafficDistributionMeta = () => {
    return this.get(`/client/api/v2/store-meta/global_traffic_distribution_meta`)
  }

  fetchShippingInfo = () => {
    return this.get('/client/api/v2/store-meta/shippingDetail')
  }

  fetchAfterOrderShareInfo = () => {
    return this.get('/client/api/v2/store-meta/after_order_share_page_meta')
  }

  fetchPiggyTasksMeta = () => {
    return this.get('/client/api/v2/store-meta/piggy_task_meta')
  }

  fetchCoinsIntroduction = () => {
    return this.get('/client/api/v2/store-meta/coinsIntroduction')
  }

  fetchVipInfo = () => {
    return this.get('/client/api/v2/store-meta/vip_info')
  }

  fetchNewUserPageInfo = () => {
    return this.get('/client/api/v2/store-meta/new_user_page_info')
  }

  fetchGiftGalaxyInfo = () => {
    return this.get('/client/api/v2/store-meta/gift_galaxy')
  }

  fetchActivityMeta() {
    return retryIfTimeout(
      () => this.get('/client/api/v2/store-meta/activity_meta')
    )
  }
}

export default LayoutService
