import React, { useEffect } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'

import Image from '../../common/Image/Image'
import Countdown from '../../Widget/Countdown/Countdown'
import cx from '../../../utils/className'

import styles from './UnpaidCart.module.css'

import { withModal } from '../../common/Modal/Modal'

const EVENT_CATEGORY_UNPAID = 'UnpaidCart'

const UnpaidCart = props => {

  const { locale } = props.$site.getSiteInfo()
  const isUS = locale === 'en_US'


  const trans = key => {
    return props.$i18n.transl(`core.checkout.${key}`)
  }

  useEffect(() => {
    props.$track.event(EVENT_CATEGORY_UNPAID, 'view')
  }, [props.$track])

  const lineItems = (props.cart && props.cart.lineItems) || []

  const coupon = (props.coupons || [])[0]

  const renderContent = () =>{
    return (
      <div className={styles.contentWrapper}>
        <div className={styles.subtitle}>{trans('getBeforeExpired')}</div>
          <div className={styles.couponWrapper}>
            <div className={styles.discount}>{coupon.discount}</div>
            <div className={styles.off}>{coupon.off}</div>
            <div className={styles.countdownWrapper}>
              {props.$i18n.transl('core.promotion.ends')}
              <Countdown
                className={styles.countdownClassName}
                cardClassName={styles.cardClassName}
                separatorClassName={styles.separatorClassName}
                timestamp={900000}
                type={Countdown.TYPE.CLOCK}
              />
           </div>
          </div>
          <div className={styles.cartItems}>
            {
              lineItems.map(item => {
                return (
                  <div key={item.id} className={styles.cartItem}>
                    <Image src={item.image} small crop={{ width: 1, height: 1 }} placeholder={{ width: 1, height: 1 }}/>
                    <div className={styles.mask}>{isUS ? 'In Cart' : ''}</div>
                  </div>
                )
              })
            }
          </div>
        </div>
    )
  }

  return (
    <div className={cx(styles.wrapper, isUS && styles.us)}>
      <div className={styles.closeIcon} onClick={props.onClose}>&#xe60e;</div>
      <div className={styles.title}>{trans('limitTimeOffer')}</div>
        {
          renderContent()
        }
      <div className={styles.continue} onClick={props.onConfirm}>{trans('unlockOffer')}</div>
    </div>
  )
}

export default withModal(withPlugin(UnpaidCart), { showCloseButton: false, disableCoverClick: true })
