import React, { useEffect, useRef } from 'react'
import styles from './Drawer.module.css'
import cx from '../../../utils/className'
import { withPlugin } from '@flamingo_tech/funkgo'
import { createPortal } from 'react-dom'

const DRAWER_POSITION = {
  BOTTOM: styles.bottom,
  RIGHT: styles.right
}

const withDrawer = (Component, { fullScreen = false, needScroll = true, overflow = false, position, headerSetting = {}, coverClass, bodyClass, contentClass, disableCoverClick } = {}) => {
  const WrappedComponent = (props) => {
    const isDesktop = props.$detector.isDesktop()
    const isApp = props.$detector.isApp()
    const barTopRef = useRef()
    const scrollRef = useRef()
    const isExistDrawer = document.getElementsByClassName(styles.drawer)

    useEffect(() => {
      // 记录当前点击的位置
      if (barTopRef && isExistDrawer.length === 1) {
        barTopRef.current = document.documentElement.scrollTop

        const innerWidth = window.innerWidth
        const clientWidth = document.body.clientWidth
        const barWidth = innerWidth - clientWidth

        document.body.style.top = `-${barTopRef.current}px`
        document.body.style.overflow = 'hidden'
        document.body.style.position = 'fixed' // 通过 fixed 方式呼出底部工具栏
        document.body.style.width = '100%'
        document.body.style.height = '100%'

        if (isDesktop) {
          document.body.style.paddingRight = `${barWidth}px` // 如果是移动端，变成 overflow hidden 时，scroll bar 会消失 需要增加一个占位
        }

        return () => {
          document.body.removeAttribute('style')
          window.scrollTo({ top: barTopRef.current, behavior: 'instant' })
        }
      }
    }, [isDesktop, isExistDrawer])

    const handleClose = () => {
      props.onClose()
    }

    const renderHeaderElement = (title, showCloseIcon) => {
      if (!title && !showCloseIcon) {
        return null
      }
      return (
        <div className={cx(styles.header, props.headerClass)}>
          {title}
          <span className={styles.closeIcon} onClick={handleClose}>&#xe60e;</span>
        </div>
      )
    }

    const drawerPosition = props.position || position

    const divStyles = [
      styles.drawer,
      drawerPosition ? drawerPosition : (isDesktop ? DRAWER_POSITION.RIGHT : DRAWER_POSITION.BOTTOM),
      isDesktop && styles.isDesktop,
      fullScreen && styles.fullScreen,
      (props.headerTitle || props.showCloseIcon) && styles.headerTitle,
      needScroll && styles.needScroll,
      overflow && styles.overflow
    ]

    const { HeaderComponent } = headerSetting
    return (
      createPortal((
        <div className={cx(...divStyles)}>
          <div className={cx(styles.cover, coverClass)} onClick={disableCoverClick ? () => null :handleClose}></div>
          <div className={cx(styles.content, isApp && styles.isApp, contentClass)}>
            {HeaderComponent ? <HeaderComponent {...props} /> : renderHeaderElement(props.headerTitle, props.showCloseIcon)}
            <div className={cx(styles.body, bodyClass)} ref={ref => scrollRef.current = ref}>
              <Component {...props} onClose={handleClose} scrollRef={scrollRef} />
            </div>
          </div>
        </div>
      ), document.body)
    )
  }

  WrappedComponent.displayName = `withDrawer(${Component.displayName || Component.name})`

  return withPlugin(props => {
    return <WrappedComponent {...props} />
  })
}

export {
  withDrawer,
  DRAWER_POSITION
}
