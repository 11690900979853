import React from 'react'

import withCartAndPlugins from '../../../hooks/withCartAndPlugins'
import DesktopUserCenterShell from './DesktopUserCenterShell'

import {
  useShare,
  useHeaderProps,
} from './hooks/pageShellHooks'

import withBackWrap from '../../../hooks/withBackWrap'

import DesktopHeader from '../Header/DesktopHeader'
import DesktopNav from '../Navi/DesktopNav'
import DesktopFooter from '../Footer/DesktopFooter'

import cx from '../../../utils/className'
import { getCartLineItemCount } from '../../../utils/cartUtils'
import styles from './DesktopPageShell.module.css'


const DesktopPageShell = props => {
  const headerProps = useHeaderProps(props)
  const share = useShare(props)

  const {
    $router,
    children,
    naviItems,
    $store,
    cart,
  } = props

  const handleShareClick = share
  const handleCartClick = () => $router.navigate({
    name: 'Cart'
  })

  const handleSearch = value => {
    $router.navigate({
      name: 'Search',
      search: {
        q: value
      }
    })
  }

  return (
    <section className={styles.wrapper}>
      <section className={styles.page}>
        <div className={styles.stickyHeader}>
          <DesktopHeader
            {...headerProps}
            cartLineItemCount={getCartLineItemCount(cart)}
            $store={$store}
            onCartClick={handleCartClick}
            onShareClick={handleShareClick}
          ></DesktopHeader>
          <DesktopNav
            onSearch={handleSearch}
            items={naviItems}
          ></DesktopNav>
        </div>
        <DesktopUserCenterShell show={props.showDesktopUserCenterShell}>
          <main className={cx(styles.content, props.supportDesktop && styles.desktopScreen, props.desktopFullScreen && styles.fullScreen)} style={props.desktopCenterShellStyles}>
            {children}
          </main>
        </DesktopUserCenterShell>
        <DesktopFooter />
      </section>
    </section>
  )
}

export default withBackWrap(withCartAndPlugins(DesktopPageShell))
