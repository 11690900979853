import queryString from 'query-string'

export const RECOMMENDATIONS_COLLECTION_HANDLE = 'rch'
export const RECOMMENDATIONS_ES_COLLECTION_HANDLE = 'rech'
export const TRACK_CHANNEL = '_channel'

// products whose category belongs to electronics and nails has no size chart
export const productTypeWithoutSizeCart = ['Phone Cases', 'Android Phone Cases', 'Airpods Cases', 'Earphones', 'Kindle Cover', 'Electronics::Accessories', 'Nails']

// get all variants which are related to current variant option
export const getVariantsByVariantOptions = (options, variants) => {
  const keys = Object.keys(options)
  if (keys.length <= 0) {
    return []
  }

  return variants.filter(variant => {
    const selectedOptionMap = {}
    variant.selectedOptions.forEach(selectedOption => {
      selectedOptionMap[selectedOption.name] = selectedOption.value
    })

    let isSelected = true
    for (let option in options) {
      if (!(selectedOptionMap[option] && selectedOptionMap[option] === options[option])) {
        isSelected = false
      }
    }
    return isSelected
  })
}

export const isVariantsDisabledByVariantOptions = (options, variants) => {
  const vars = getVariantsByVariantOptions(options, variants)
  let isAvailable = false
  vars.forEach(variant => {
    if (variant.availableForSale) {
      isAvailable = true
    }
  })
  return !isAvailable
}


export const getProductDetailTrackChannel = location => {
  let channel

  if (location && location.search) {
    const query = queryString.parse(location.search)

    channel = query[TRACK_CHANNEL]
  }

  return channel || ''
}

export const setDefaultSelectedOptionValues = (product) => {
  let currentVariantInfo = {}
  const { variations = [], options } = product
  const availableVariations = variations.filter(i => i.availableForSale)

  if (availableVariations.length === 1) { // 单 sku
    const { selectedOptions } = availableVariations[0]

    selectedOptions.forEach(i => {
      currentVariantInfo[i.name] = i.value
    })
  } else if (options.length >= 2) {
    const variantOption = options[0]
    for (let i = 0; i < variantOption.values.length; i++) {
      const disabled = isVariantsDisabledByVariantOptions({
        [variantOption.name]: variantOption.values[i]
      }, variations)

      if (!disabled) {
        currentVariantInfo[variantOption.name] = variantOption.values[i]
        break
      }
    }
  }
  return currentVariantInfo
}


export const getPriceList = props => data => {
  const {
    appliedCoupon,
    discountTag,
    discountFee,
    freight,
    displayFreight,
    displayDiscountFee,
    displayProductFee,
    displayTotalFee,
    displayTotalSaveFee,
    totalSaveFee,
    checkoutItemCount,
    coinsFee,
    deductCoin,
    displayCoinsFee,
    couponName
  } = data

  const { showSubTotal, showDiscount } = props

  const priceList = []

  // line items is durning user update on local site, cannot calculate real price at this time
  if (checkoutItemCount > 0) {
    if (showSubTotal) {
      priceList.push({
        title: props.$i18n.transl('core.checkout.subtotal'),
        value: displayProductFee
      })
    } else {
      priceList.push({
        title: checkoutItemCount > 1 ? `${checkoutItemCount} ${props.$i18n.transl('core.checkout.items')}` : `${checkoutItemCount} ${props.$i18n.transl('core.cart.item')}`,
        value: displayProductFee
      })
    }

    // discount
    if (discountFee > 0 || showDiscount) {
      let couponTitle


      if (appliedCoupon && appliedCoupon.meta) {
        couponTitle = appliedCoupon.meta.title
      } else if (couponName) {
        couponTitle = couponName
      }

      const discountItem = {
        title: !!discountTag ? couponTitle : props.$i18n.transl('core.checkout.discount'),
        value: `${displayDiscountFee}`,
        negative: true,
        classKey: 'discount',
        subTitle: !discountTag ? ` (${couponTitle})` : '',
        tag: discountTag
      }

      priceList.push(discountItem)
    }


    // coinsFee
    if (coinsFee > 0) {
      let subTitle = ''
      if (deductCoin) {
        subTitle = `(- ${props.$i18n.transl('core.checkout.coinsNum', {
          count: parseInt(deductCoin).toLocaleString()
        })})`
      }

      priceList.push({
        title: props.$i18n.transl('core.checkout.coinsExchange'),
        value: displayCoinsFee,
        subTitle,
        negative: true
      })
    }


    priceList.push({
      title: props.$i18n.transl('core.checkout.shippingFee'),
      value: freight > 0 ? displayFreight : props.$i18n.transl('core.checkout.shippingFeeFree'),
      classKey: 'shippingFee'
    })
  }

  if (!props.hideTotal) {
    priceList.push({
      title: props.$i18n.transl('core.checkout.total'),
      value: displayTotalFee,
      classKey: 'orderTotal'
    })
  }

  if (totalSaveFee && totalSaveFee !== 0) {
    priceList.push({
      title: props.$i18n.transl('core.cart.youSave'),
      value: displayTotalSaveFee,
      classKey: 'totalSaveFee'
    })
  }



  return priceList
}