import React, { PureComponent } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'

import CartService from '../plugins/StorePlugin/services/CartService'

const DISPLAY_CART_ITEM_QUANTITY = 3

const withUnpaidCartEnabled = (Component) => {
  const displayName = `withUnpaidCartEnabled(${Component.displayName || Component.name})`

  class WrappedComponent extends PureComponent {
    cartService = new CartService(this.props.$http)

    unpaidInfoStorage = this.props.$storage.create('unpaid_cart_info', { strategy: 'SESSION' })

    state = {
      loaded: false
    }

    componentDidMount() {
      const unpaidInfo = this.unpaidInfoStorage.getItem()

      const cartId = this.props.$store.getCurrentCartId()

      if (cartId && !unpaidInfo) {
        this.cartService.getPreviewCart(cartId).then(data => {

          const cartInfo = {
            id: cartId,
            lineItems: data.slice(0, DISPLAY_CART_ITEM_QUANTITY).map(lineItem => ({
              id: lineItem.skuId,
              image: lineItem.mainImage
            })),
            itemQuantity: data.length
          }

          this.unpaidInfoStorage.setItem(cartInfo)

          this.setState({
            loaded: true
          })
        }).catch(() => {
          this.setState({ loaded: true })
        })
      } else {
        this.setState({ loaded: true })
      }
    }


    render() {
      if (!this.state.loaded) {
        return null
      }

      return (
        <Component
          {...this.props}
          cart={this.unpaidInfoStorage.getItem()}
        />
      )
    }
  }

  WrappedComponent.displayName = displayName
  return withPlugin(WrappedComponent)
}

export default withUnpaidCartEnabled
