import ProductDetailContainer from './containers/Store/ProductDetailContainer'
import CollectionContainer from './containers/Store/CollectionContainer'
import EntryContainer from './containers/Entry/EntryContainer'
import CartContainer from './containers/Store/CartContainer'
import PaymentMethodContainer from './containers/Store/PaymentMethodContainer'
import PurchaseProtectionContainer from './containers/Store/PurchaseProtectionContainer'

const NewContainer = {
  loader: () => import('./containers/Store/NewContainer'),
  modules: ['./containers/Store/NewContainer'],
  webpack: () => [require.resolveWeak('./containers/Store/NewContainer')]
}

const SalesEventContainer = {
  loader: () => import('./containers/Store/SalesEventContainer'),
  modules: ['./containers/Store/SalesEventContainer'],
  webpack: () => [require.resolveWeak('./containers/Store/SalesEventContainer')]
}

const SalesEventsContainer = {
  loader: () => import('./containers/Store/SalesEventsContainer'),
  modules: ['./containers/Store/SalesEventsContainer'],
  webpack: () => [require.resolveWeak('./containers/Store/SalesEventsContainer')]
}

const PureSalesEventContainer = {
  loader: () => import('./containers/Store/PureSalesEventContainer'),
  modules: ['./containers/Store/PureSalesEventContainer'],
  webpack: () => [require.resolveWeak('./containers/Store/PureSalesEventContainer')]
}

// const CollectionContainer = {
//   loader: () => import('./containers/Store/CollectionContainer'),
//   modules: ['./containers/Store/CollectionContainer'],
//   webpack: () => [require.resolveWeak('./containers/Store/CollectionContainer')]
// }

const ESCollectionContainer = {
  loader: () => import('./containers/Store/ESCollectionContainer'),
  modules: ['./containers/Store/ESCollectionContainer'],
  webpack: () => [require.resolveWeak('./containers/Store/ESCollectionContainer')]
}

const FlashSaleContainer = {
  loader: () => import('./containers/Store/FlashSaleContainer'),
  modules: ['./containers/Store/FlashSaleContainer'],
  webpack: () => [require.resolveWeak('./containers/Store/FlashSaleContainer')]
}

const SearchContainer = {
  loader: () => import('./containers/Store/SearchContainer'),
  modules: ['./containers/Store/SearchContainer'],
  webpack: () => [require.resolveWeak('./containers/Store/SearchContainer')]
}
const SearchResultContainer = {
  loader: () => import('./containers/Store/SearchResultContainer'),
  modules: ['./containers/Store/SearchResultContainer'],
  webpack: () => [require.resolveWeak('./containers/Store/SearchResultContainer')]
}

const ProductCommentContainer = {
  loader: () => import('./containers/Store/ProductCommentContainer'),
  modules: ['./containers/Store/ProductCommentContainer'],
  webpack: () => [require.resolveWeak('./containers/Store/ProductCommentContainer')]
}
const CouponCenterContainer = {
  loader: () => import('./containers/Store/CouponCenterContainer'),
  modules: ['./containers/Store/CouponCenterContainer'],
  webpack: () => [require.resolveWeak('./containers/Store/CouponCenterContainer')]
}
const CheckOutContainer = {
  loader: () => import('./containers/Store/CheckOutContainer'),
  modules: ['./containers/Store/CheckOutContainer'],
  webpack: () => [require.resolveWeak('./containers/Store/CheckOutContainer')]
}
const ShippingConfirmationContainer = {
  loader: () => import('./containers/Store/ShippingConfirmationContainer'),
  modules: ['./containers/Store/ShippingConfirmationContainer'],
  webpack: () => [require.resolveWeak('./containers/Store/ShippingConfirmationContainer')]
}
const GuestFirstAddressContainer = {
  loader: () => import('./containers/Store/GuestFirstAddressContainer'),
  modules: ['./containers/Store/GuestFirstAddressContainer'],
  webpack: () => [require.resolveWeak('./containers/Store/GuestFirstAddressContainer')]
}
const UserAddressEditContainer = {
  loader: () => import('./containers/Store/UserAddressEditContainer'),
  modules: ['./containers/Store/UserAddressEditContainer'],
  webpack: () => [require.resolveWeak('./containers/Store/UserAddressEditContainer')]
}
const ProductCategoryContainer = {
  loader: () => import('./containers/Store/ProductCategoryContainer'),
  modules: ['./containers/Store/ProductCategoryContainer'],
  webpack: () => [require.resolveWeak('./containers/Store/ProductCategoryContainer')]
}
const SecondCategoryContainer = {
  loader: () => import('./containers/Store/SecondCategoryContainer'),
  modules: ['./containers/Store/SecondCategoryContainer'],
  webpack: () => [require.resolveWeak('./containers/Store/SecondCategoryContainer')]
}
// const CartContainer = {
//   loader: () => import('./containers/Store/CartContainer'),
//   modules: ['./containers/Store/CartContainer'],
//   webpack: () => [require.resolveWeak('./containers/Store/CartContainer')]
// }
const BaseWebviewContainer = {
  loader: () => import('./containers/Store/BaseWebviewContainer'),
  modules: ['./containers/Store/BaseWebviewContainer'],
  webpack: () => [require.resolveWeak('./containers/Store/BaseWebviewContainer')]
}

const GirlsShowDetailContainer = {
  loader: () => import('./containers/GirlsShow/GirlsShowDetailContainer'),
  modules: ['./containers/GirlsShow/GirlsShowDetailContainer'],
  webpack: () => [require.resolveWeak('./containers/GirlsShow/GirlsShowDetailContainer')]
}
const GirlsShowHomeContainer = {
  loader: () => import('./containers/GirlsShow/GirlsShowHomeContainer'),
  modules: ['./containers/GirlsShow/GirlsShowHomeContainer'],
  webpack: () => [require.resolveWeak('./containers/GirlsShow/GirlsShowHomeContainer')]
}
const GirlsShowPublishContainer = {
  loader: () => import('./containers/GirlsShow/GirlsShowPublishContainer'),
  modules: ['./containers/GirlsShow/GirlsShowPublishContainer'],
  webpack: () => [require.resolveWeak('./containers/GirlsShow/GirlsShowPublishContainer')]
}
const GirlsShowCommentsContainer = {
  loader: () => import('./containers/GirlsShow/GirlsShowCommentsContainer'),
  modules: ['./containers/GirlsShow/GirlsShowCommentsContainer'],
  webpack: () => [require.resolveWeak('./containers/GirlsShow/GirlsShowCommentsContainer')]
}
const GirlsShowPersonalContainer = {
  loader: () => import('./containers/GirlsShow/GirlsShowPersonalContainer'),
  modules: ['./containers/GirlsShow/GirlsShowPersonalContainer'],
  webpack: () => [require.resolveWeak('./containers/GirlsShow/GirlsShowPersonalContainer')]
}
const GirlsShowMyHomeContainer = {
  loader: () => import('./containers/GirlsShow/GirlsShowMyHomeContainer'),
  modules: ['./containers/GirlsShow/GirlsShowMyHomeContainer'],
  webpack: () => [require.resolveWeak('./containers/GirlsShow/GirlsShowMyHomeContainer')]
}
const GirlsShowRewardContainer = {
  loader: () => import('./containers/GirlsShow/GirlsShowRewardContainer'),
  modules: ['./containers/GirlsShow/GirlsShowRewardContainer'],
  webpack: () => [require.resolveWeak('./containers/GirlsShow/GirlsShowRewardContainer')]
}
const TagHomeContainer = {
  loader: () => import('./containers/GirlsShow/TagHomeContainer'),
  modules: ['./containers/GirlsShow/TagHomeContainer'],
  webpack: () => [require.resolveWeak('./containers/GirlsShow/TagHomeContainer')]
}

const OrderDetailForEmailContainer = {
  loader: () => import('./containers/Order/OrderDetailForEmailContainer'),
  modules: ['./containers/Order/OrderDetailForEmailContainer'],
  webpack: () => [require.resolveWeak('./containers/Order/OrderDetailForEmailContainer')]
}

const OrderDetailForInnerContainer = {
  loader: () => import('./containers/Order/OrderDetailForInnerContainer'),
  modules: ['./containers/Order/OrderDetailForInnerContainer'],
  webpack: () => [require.resolveWeak('./containers/Order/OrderDetailForInnerContainer')]
}

const OrderDetailForShopifyContainer = {
  loader: () => import('./containers/Order/OrderDetailForShopifyContainer'),
  modules: ['./containers/Order/OrderDetailForShopifyContainer'],
  webpack: () => [require.resolveWeak('./containers/Order/OrderDetailForShopifyContainer')]
}

const AfterOrderShareContainer = {
  loader: () => import('./containers/Order/AfterOrderShareContainer'),
  modules: ['./containers/Order/AfterOrderShareContainer'],
  webpack: () => [require.resolveWeak('./containers/Order/AfterOrderShareContainer')]
}

const AfterOrderInviteContainer = {
  loader: () => import('./containers/Order/AfterOrderInviteContainer'),
  modules: ['./containers/Order/AfterOrderInviteContainer'],
  webpack: () => [require.resolveWeak('./containers/Order/AfterOrderInviteContainer')]
}

const AfterOrderFreeGiftClaimContainer = {
  loader: () => import('./containers/Order/AfterOrderFreeGiftClaimContainer'),
  modules: ['./containers/Order/AfterOrderFreeGiftClaimContainer'],
  webpack: () => [require.resolveWeak('./containers/Order/AfterOrderFreeGiftClaimContainer')]
}

const AfterOrderCampaignContainer = {
  loader: () => import('./containers/Order/AfterOrderCampaignContainer'),
  modules: ['./containers/Order/AfterOrderCampaignContainer'],
  webpack: () => [require.resolveWeak('./containers/Order/AfterOrderCampaignContainer')]
}

const AfterOrderFreeGiftContainer = {
  loader: () => import('./containers/Order/AfterOrderFreeGiftContainer'),
  modules: ['./containers/Order/AfterOrderFreeGiftContainer'],
  webpack: () => [require.resolveWeak('./containers/Order/AfterOrderFreeGiftContainer')]
}

const CommentCollectContainer = {
  loader: () => import('./containers/Order/CommentCollectContainer'),
  modules: ['./containers/Order/CommentCollectContainer'],
  webpack: () => [require.resolveWeak('./containers/Order/CommentCollectContainer')]
}

const CommentSuccessContainer = {
  loader: () => import('./containers/Order/CommentSuccessContainer'),
  modules: ['./containers/Order/CommentSuccessContainer'],
  webpack: () => [require.resolveWeak('./containers/Order/CommentSuccessContainer')]
}

const CommentReviewedContainer = {
  loader: () => import('./containers/Order/CommentReviewedContainer'),
  modules: ['./containers/Order/CommentReviewedContainer'],
  webpack: () => [require.resolveWeak('./containers/Order/CommentReviewedContainer')]
}

const CommentPublishContainer = {
  loader: () => import('./containers/Order/CommentPublishContainer'),
  modules: ['./containers/Order/CommentPublishContainer'],
  webpack: () => [require.resolveWeak('./containers/Order/CommentPublishContainer')]
}
const OrdersContainer = {
  loader: () => import('./containers/Order/OrdersContainer'),
  modules: ['./containers/Order/OrdersContainer'],
  webpack: () => [require.resolveWeak('./containers/Order/OrdersContainer')]
}
const PaymentSuccessContainer = {
  loader: () => import('./containers/Order/PaymentSuccessContainer'),
  modules: ['./containers/Order/PaymentSuccessContainer'],
  webpack: () => [require.resolveWeak('./containers/Order/PaymentSuccessContainer')]
}
const FBRedirectPageContainer = {
  loader: () => import('./containers/Redirect/FBRedirectPageContainer'),
  modules: ['./containers/Redirect/FBRedirectPageContainer'],
  webpack: () => [require.resolveWeak('./containers/Redirect/FBRedirectPageContainer')]
}
const GoogleRedirectPageContainer = {
  loader: () => import('./containers/Redirect/GoogleRedirectPageContainer'),
  modules: ['./containers/Redirect/GoogleRedirectPageContainer'],
  webpack: () => [require.resolveWeak('./containers/Redirect/GoogleRedirectPageContainer')]
}
const ManyChatRedirectPageContainer = {
  loader: () => import('./containers/Redirect/ManyChatRedirectPageContainer'),
  modules: ['./containers/Redirect/ManyChatRedirectPageContainer'],
  webpack: () => [require.resolveWeak('./containers/Redirect/ManyChatRedirectPageContainer')]
}
const DownloadContainer = {
  loader: () => import('./containers/Download/DownloadContainer'),
  modules: ['./containers/Download/DownloadContainer'],
  webpack: () => [require.resolveWeak('./containers/Download/DownloadContainer')]
}
const WebviewReuseContainer = {
  loader: () => import('./containers/Reuse/WebviewReuseContainer'),
  modules: ['./containers/Reuse/WebviewReuseContainer'],
  webpack: () => [require.resolveWeak('./containers/Reuse/WebviewReuseContainer')]
}
const LoginContainer = {
  loader: () => import('./containers/Login/LoginContainer'),
  modules: ['./containers/Login/LoginContainer'],
  webpack: () => [require.resolveWeak('./containers/Login/LoginContainer')]
}
const ProfileContainer = {
  loader: () => import('./containers/User/ProfileContainer'),
  modules: ['./containers/User/ProfileContainer'],
  webpack: () => [require.resolveWeak('./containers/User/ProfileContainer')]
}
const VipContainer = {
  loader: () => import('./containers/User/VipContainer'),
  modules: ['./containers/User/VipContainer'],
  webpack: () => [require.resolveWeak('./containers/User/VipContainer')]
}
const ProfileEditContainer = {
  loader: () => import('./containers/User/ProfileEditContainer'),
  modules: ['./containers/User/ProfileEditContainer'],
  webpack: () => [require.resolveWeak('./containers/User/ProfileEditContainer')]
}
const ChooseFavoriteCategoryContainer = {
  loader: () => import('./containers/User/ChooseFavoriteCategoryContainer'),
  modules: ['./containers/User/ChooseFavoriteCategoryContainer'],
  webpack: () => [require.resolveWeak('./containers/User/ChooseFavoriteCategoryContainer')]
}
const AccountDeletionContainer = {
  loader: () => import('./containers/User/AccountDeletionContainer'),
  modules: ['./containers/User/AccountDeletionContainer'],
  webpack: () => [require.resolveWeak('./containers/User/AccountDeletionContainer')]
}
const SettingContainer = {
  loader: () => import('./containers/User/SettingContainer'),
  modules: ['./containers/User/SettingContainer'],
  webpack: () => [require.resolveWeak('./containers/User/SettingContainer')]
}
const WishListContainer = {
  loader: () => import('./containers/User/WishListContainer'),
  modules: ['./containers/User/WishListContainer'],
  webpack: () => [require.resolveWeak('./containers/User/WishListContainer')]
}
const PiggyBankContainer = {
  loader: () => import('./containers/Game/PiggyBankContainer'),
  modules: ['./containers/Game/PiggyBankContainer'],
  webpack: () => [require.resolveWeak('./containers/Game/PiggyBankContainer')]
}
const PiggyBankHitBoxGameContainer = {
  loader: () => import('./containers/Game/PiggyBankHitBoxGameContainer'),
  modules: ['./containers/Game/PiggyBankHitBoxGameContainer'],
  webpack: () => [require.resolveWeak('./containers/Game/PiggyBankHitBoxGameContainer')]
}
const PiggyBankHitBoxGameGuideContainer = {
  loader: () => import('./containers/Game/PiggyBankHitBoxGameGuideContainer'),
  modules: ['./containers/Game/PiggyBankHitBoxGameGuideContainer'],
  webpack: () => [require.resolveWeak('./containers/Game/PiggyBankHitBoxGameGuideContainer')]
}
const CoinsIntroductionContainer = {
  loader: () => import('./containers/Game/CoinsIntroductionContainer'),
  modules: ['./containers/Game/CoinsIntroductionContainer'],
  webpack: () => [require.resolveWeak('./containers/Game/CoinsIntroductionContainer')]
}
const GiftGalaxyContainer = {
  loader: () => import('./containers/Game/GiftGalaxyContainer'),
  modules: ['./containers/Game/GiftGalaxyContainer'],
  webpack: () => [require.resolveWeak('./containers/Game/GiftGalaxyContainer')]
}
const InfoContainer = {
  loader: () => import('./containers/Info/InfoContainer'),
  modules: ['./containers/Info/InfoContainer'],
  webpack: () => [require.resolveWeak('./containers/Info/InfoContainer')]
}

const PolicyContainer = {
  loader: () => import('./containers/Info/PolicyContainer'),
  modules: ['./containers/Info/PolicyContainer'],
  webpack: () => [require.resolveWeak('./containers/Info/PolicyContainer')]
}

const EmailRedirectContainer = {
  loader: () => import('./containers/Email/EmailRedirectContainer'),
  modules: ['./containers/Email/EmailRedirectContainer'],
  webpack: () => [require.resolveWeak('./containers/Email/EmailRedirectContainer')]
}
const UnsubscribeContainer = {
  loader: () => import('./containers/Email/UnsubscribeContainer'),
  modules: ['./containers/Email/UnsubscribeContainer'],
  webpack: () => [require.resolveWeak('./containers/Email/UnsubscribeContainer')]
}

const SiteSettingContainer = {
  loader: () => import('./containers/Shell/SiteSettingContainer'),
  modules: ['./containers/Shell/SiteSettingContainer'],
  webpack: () => [require.resolveWeak('./containers/Shell/SiteSettingContainer')]
}

const NotFoundPageContainer = {
  loader: () => import('./containers/Error/NotFoundPageContainer'),
  modules: ['./containers/Error/NotFoundPageContainer'],
  webpack: () => [require.resolveWeak('./containers/Error/NotFoundPageContainer')]
}

const KlarnaCampaignContainer = {
  loader: () => import('./containers/Store/KlarnaCampaignContainer'),
  modules: ['./containers/Store/KlarnaCampaignContainer'],
  webpack: () => [require.resolveWeak('./containers/Store/KlarnaCampaignContainer')]
}

const NewUserEventContainer = {
  loader: () => import('./containers/Event/NewUserEventContainer'),
  modules: ['./containers/Event/NewUserEventContainer'],
  webpack: () => [require.resolveWeak('./containers/Event/NewUserEventContainer')]
}

const CreditBackRewardContainer = {
  loader: () => import('./containers/CreditBack/CreditBackRewardContainer'),
  modules: ['./containers/CreditBack/CreditBackRewardContainer'],
  webpack: () => [require.resolveWeak('./containers/CreditBack/CreditBackRewardContainer')]
}

const CreditBackHomeContainer = {
  loader: () => import('./containers/CreditBack/CreditBackHomeContainer'),
  modules: ['./containers/CreditBack/CreditBackHomeContainer'],
  webpack: () => [require.resolveWeak('./containers/CreditBack/CreditBackHomeContainer')]
}

const FreebiePlusRewardContainer = {
  loader: () => import('./containers/FreebiePlus/FreebiePlusRewardContainer'),
  modules: ['./containers/FreebiePlus/FreebiePlusRewardContainer'],
  webpack: () => [require.resolveWeak('./containers/FreebiePlus/FreebiePlusRewardContainer')]
}

const FreebiePlusHomeContainer = {
  loader: () => import('./containers/FreebiePlus/FreebiePlusHomeContainer'),
  modules: ['./containers/FreebiePlus/FreebiePlusHomeContainer'],
  webpack: () => [require.resolveWeak('./containers/FreebiePlus/FreebiePlusHomeContainer')]
}

const FreebiePlusDownloadLandingContainer = {
  loader: () => import('./containers/FreebiePlus/FreebiePlusDownloadLandingContainer'),
  modules: ['./containers/FreebiePlus/FreebiePlusDownloadLandingContainer'],
  webpack: () => [require.resolveWeak('./containers/FreebiePlus/FreebiePlusDownloadLandingContainer')]
}

const SmsLandingContainer = {
  loader: () => import('./containers/SmsLanding/SmsLandingContainer'),
  modules: ['./containers/SmsLanding/SmsLandingContainer'],
  webpack: () => [require.resolveWeak('./containers/SmsLanding/SmsLandingContainer')]
}

const FreebieUltraRewardContainer = {
  loader: () => import('./containers/FreebiePlus/FreebieUltraRewardContainer'),
  modules: ['./containers/FreebiePlus/FreebieUltraRewardContainer'],
  webpack: () => [require.resolveWeak('./containers/FreebiePlus/FreebieUltraRewardContainer')]
}

const FreebieUltraHomeContainer = {
  loader: () => import('./containers/FreebiePlus/FreebieUltraHomeContainer'),
  modules: ['./containers/FreebiePlus/FreebieUltraHomeContainer'],
  webpack: () => [require.resolveWeak('./containers/FreebiePlus/FreebieUltraHomeContainer')]
}

const FreebieUltraMaxRewardContainer = {
  loader: () => import('./containers/FreebiePlus/FreebieUltraMaxRewardContainer'),
  modules: ['./containers/FreebiePlus/FreebieUltraMaxRewardContainer'],
  webpack: () => [require.resolveWeak('./containers/FreebiePlus/FreebieUltraMaxRewardContainer')]
}

const FreebieUltraMaxHomeContainer = {
  loader: () => import('./containers/FreebiePlus/FreebieUltraMaxHomeContainer'),
  modules: ['./containers/FreebiePlus/FreebieUltraMaxHomeContainer'],
  webpack: () => [require.resolveWeak('./containers/FreebiePlus/FreebieUltraMaxHomeContainer')]
}

const NewbieHomeContainer = {
  loader: () => import('./containers/Newbie/NewbieHomeContainer'),
  modules: ['./containers/Newbie/NewbieHomeContainer'],
  webpack: () => [require.resolveWeak('./containers/Newbie/NewbieHomeContainer')]
}

const corePages = [
  {
    name: 'Home',
    path: ['/store/', '/store/home'],

    scheme: '/shop',
    preferNative: true,

    exact: true,
    component: EntryContainer,
  },
  {
    name: 'SiteSetting',
    path: '/store/site_setting',
    exact: true,
    component: SiteSettingContainer
  },
  {
    name: 'New',
    path: '/store/new',

    scheme: '/new',
    preferNative: true,

    exact: true,
    component: NewContainer
  },
  {
    name: 'KlarnaCampaign',
    path: '/store/klarna_campaign',

    scheme: '/KlarnaCampaign',
    preferNative: true,

    exact: true,
    component: KlarnaCampaignContainer
  },
  {
    name: 'ShippingConfirmation',
    path: '/store/checkout/shipping_confirmation',

    exact: true,
    component: ShippingConfirmationContainer
  },
  {
    name: 'Checkout',
    path: '/store/checkout',

    exact: true,
    component: CheckOutContainer
  },
  {
    name: 'GuestFirstAddress',
    path: '/store/guest_first_address',
    component: GuestFirstAddressContainer
  },
  {
    name: 'FBRedirectPage',
    path: '/store/fb_redirect',
    component: FBRedirectPageContainer
  },
  {
    name: 'GoogleRedirectPage',
    path: '/store/google_redirect',
    component: GoogleRedirectPageContainer
  },
  {
    name: 'ManyChatRedirectPage',
    path: '/store/many_chat_redirect',
    component: ManyChatRedirectPageContainer
  },
  {
    name: 'EmailRedirectPage',
    path: '/store/email_redirect',
    component: EmailRedirectContainer
  },
  {
    name: 'EmailUnsubscribePage',
    path: '/store/email_unsubscribe',
    component: UnsubscribeContainer
  },
  {
    name: 'Download',
    path: '/oia',
    component: DownloadContainer
  },
  {
    name: 'FreebiePlusDownloadLanding',
    path: '/store/download/freebie_plus',
    component: FreebiePlusDownloadLandingContainer
  },
  {
    name: 'Reuse',
    path: '/store/reuse',
    component: WebviewReuseContainer
  },
  {
    name: 'Login',
    path: '/store/login',
    component: LoginContainer
  },
  /** for test
  {
    name: 'Logout',
    path: '/logout',
    component: Logout
  },
  */
  {
    name: 'Cart',
    path: '/store/cart',
    component: CartContainer
  },
  {
    name: 'BaseWebview',
    path: '/store/basewebview',
    component: BaseWebviewContainer
  }
]

/* ----------------------------------- */

const shoppingPages = [
  {
    name: 'ESCollection',
    path: '/store/collections/:handle',
    component: ESCollectionContainer
  },
  {
    name: 'FlashSale',
    path: '/store/flash-sale',
    component: FlashSaleContainer
  },
  {
    name: 'Collection',
    path: '/store/collection/:handle',
    component: CollectionContainer,
    exact: true
  },
  {
    name: 'PurchaseProtection',
    path: '/store/purchase-protection',
    component: PurchaseProtectionContainer,
    exact: true
  },
  {
    name: 'PaymentMethod',
    path: '/store/event/payment-method',
    component: PaymentMethodContainer,
    exact: true
  },
  {
    name: 'SalesEvent',
    path: [
      '/store/event/:handle',
      '/campaign/event/:handle',
    ],
    component: SalesEventContainer,
    exact: true
  },
  {
    name: 'SalesEvents',
    path: [
      '/store/events/:handle',
      '/campaign/events/:handle',
    ],
    component: SalesEventsContainer
  },
  {
    name: 'PureSalesEvent',
    path: [
      '/store/pure_event/:handle',
      '/campaign/pure_event/:handle',
    ],
    component: PureSalesEventContainer
  },
  {
    name: 'Search',
    path: '/store/search/',
    component: SearchResultContainer,
    exact: true
  },
  {
    name: 'SearchLegacy',
    path: '/store/search/:handle',
    component: SearchResultContainer
  },
  {
    name: 'SearchPage',
    path: '/store/search_page',
    component: SearchContainer
  },
  {
    name: 'Product',
    path: '/store/product/:handle',

    scheme: '/product',
    preferNative: true,

    component: ProductDetailContainer
  },
  {
    name: 'ProductComment',
    path: '/store/comment/:handle',
    component: ProductCommentContainer
  },
  {
    name: 'CommentPublish',
    path: '/store/comment_publish',
    component: CommentPublishContainer
  },
  {
    name: 'ProductCategory',
    path: '/store/category',
    component: ProductCategoryContainer
  },
  {
    name: 'SecondProductCategory',
    path: '/store/second_category/:handle',
    component: SecondCategoryContainer
  },
  {
    name: 'NewUserEvent',
    path: '/store/new_user_event',
    component: NewUserEventContainer
  }
]

/* ----------------------------------- */
const orderPages = [
  {
    name: 'ShopifyOrderDetail',
    path: '/store/order/:handle',
    exact: true,
    component: OrderDetailForShopifyContainer
  },
  {
    name: 'AfterOrderShare',
    path: '/store/activity/after_order_share/:handle',
    exact: true,
    component: AfterOrderShareContainer
  },
  {
    name: 'AfterOrderInvite',
    path: '/store/activity/after_order_invite/:handle',
    exact: true,
    component: AfterOrderInviteContainer
  },
  {
    name: 'AfterOrderFreeGiftClaim',
    path: '/store/activity/after_order_free_gift_claim',
    exact: true,
    component: AfterOrderFreeGiftClaimContainer
  },
  {
    name: 'AfterOrderFreeGift',
    path: '/store/activity/after_order_free_gift',
    exact: true,
    component: AfterOrderFreeGiftContainer
  },
  {
    name: 'AfterOrderCampaignOffline',
    path: '/store/activity/after_order_campaign_offline',
    exact: true,
    component: AfterOrderCampaignContainer
  },
  {
    name: 'EmailOrderDetail',
    path: '/store/order/:handle/self',
    exact: true,
    component: OrderDetailForEmailContainer
  },
  {
    name: 'OrderDetail',
    path: '/store/order/:handle/inner',
    exact: true,
    component: OrderDetailForInnerContainer
  },
  {
    name: 'OrderLogisticsTracking',
    path: '/store/order/:handle/logistics_tracking',
    component: OrderDetailForShopifyContainer
  },
  {
    name: 'Order',
    path: '/store/order',
    exact: true,
    component: OrdersContainer
  },
  {
    name: 'CommentCollect',
    path: '/store/order/commentCreate/:orderId/:orderItemId',
    exact: true,
    component: CommentCollectContainer
  },
  {
    name: 'CommentSuccess',
    path: '/store/order/commentSuccess/:orderId/:orderItemId',
    exact: true,
    component: CommentSuccessContainer
  },
  {
    name: 'CommentReviewed',
    path: '/store/order/commentReviewed/:orderId',
    exact: true,
    component: CommentReviewedContainer
  },
  {
    name: 'PaymentSuccess',
    path: '/store/order/paymentSuccess/:handle',
    exact: true,
    component: PaymentSuccessContainer
  }
]
/* ----------------------------------- */
const userPages = [
  {
    name: 'My',
    path: '/store/user',
    exact: true,

    scheme: '/My',
    preferNative: true,

    component: ProfileContainer
  },
  {
    name: 'Vip',
    path: '/store/user/vip',
    component: VipContainer
  },
  {
    name: 'ProfileEdit',
    path: '/store/user/profile_edit',
    component: ProfileEditContainer
  },
  {
    name: 'Setting',
    path: '/store/user/setting',
    component: SettingContainer
  },
  {
    name: 'AddressEdit',
    path: '/store/user/address_edit',
    component: UserAddressEditContainer
  },
  {
    name: 'FavoriteCategory',
    path: '/store/user/choose_favorite_category',
    component: ChooseFavoriteCategoryContainer
  },
  {
    name: 'AccountDeletion',
    path: '/store/user/account_deletion',
    component: AccountDeletionContainer
  },
  {
    name: 'WishList',
    path: '/store/wish_list',
    component: WishListContainer
  }
]

/* ----------------------------------- */
const promotionPages = [
  {
    name: 'CouponCenter',
    path: '/store/coupon_center',
    component: CouponCenterContainer
  },
]
/* ----------------------------------- */
const girlsShowPages = [
  {
    name: 'GirlsShow',
    path: '/store/girls-show',
    exact: true,
    component: GirlsShowHomeContainer
  },
  {
    name: 'TagHomePage',
    path: '/store/girls-show/tag/:handle',
    exact: true,
    component: TagHomeContainer
  },
  {
    name: 'GirlsShowRewardPage',
    path: '/store/girls-show/reward_page',
    exact: true,
    component: GirlsShowRewardContainer
  },
  {
    name: 'GirlsShowPublish',
    path: '/store/girls-show/publish',
    exact: true,
    component: GirlsShowPublishContainer
  },
  {
    name: 'GirlsShowMyHomePage',
    path: '/store/girls-show/my_page',
    exact: true,
    component: GirlsShowMyHomeContainer
  },
  {
    name: 'GirlsShowDetail',
    path: '/store/girls-show/:handle',
    exact: true,
    component: GirlsShowDetailContainer
  },
  {
    name: 'GirlsShowComments',
    path: '/store/girls-show/:handle/comments',
    exact: true,
    component: GirlsShowCommentsContainer
  },
  {
    name: 'GirlsShowPersonalPage',
    path: '/store/girls-show/personal_page/:handle',
    exact: true,
    component: GirlsShowPersonalContainer
  }
]

/* ----------------------------------- */
const gamesPage = [
  {
    name: 'PiggyBank',
    path: '/store/activity/piggy_bank',
    component: PiggyBankContainer
  },
  {
    name: 'PiggyBankHitBoxGame',
    path: '/store/activity/game/piggy_bank_hit_box_game',
    component: PiggyBankHitBoxGameContainer
  },
  {
    name: 'PiggyBankHitBoxGameGuide',
    path: '/store/activity/game/piggy_bank_hit_box_game_guide',
    component: PiggyBankHitBoxGameGuideContainer
  },
  {
    name: 'CoinsIntroduction',
    path: '/store/activity/coins_introduction',
    component: CoinsIntroductionContainer
  },
  {
    name: 'GiftGalaxy',
    path: '/store/activity/gift_galaxy',
    component: GiftGalaxyContainer
  },
  {
    name: 'CreditBackReward',
    path: '/store/activity/credit_back_reward',
    component: CreditBackRewardContainer
  },
  {
    name: 'CreditBackHome',
    path: '/store/activity/credit_back',
    component: CreditBackHomeContainer
  },
  {
    name: 'FreebiePlusReward',
    path: '/store/activity/freebie_plus_reward',
    component: FreebiePlusRewardContainer
  },
  {
    name: 'FreebiePlusHome',
    path: '/store/activity/freebie_plus',
    component: FreebiePlusHomeContainer
  },
  {
    name: 'FreebieUltraReward',
    path: '/store/activity/freebie_ultra_reward',
    component: FreebieUltraRewardContainer
  },
  {
    name: 'FreebieUltraHome',
    path: '/store/activity/freebie_ultra',
    component: FreebieUltraHomeContainer
  },
  {
    name: 'FreebieUltraMaxReward',
    path: '/store/activity/freebie_ultra_max_reward',
    component: FreebieUltraMaxRewardContainer
  },
  {
    name: 'FreebieUltraMaxHome',
    path: '/store/activity/freebie_ultra_max',
    component: FreebieUltraMaxHomeContainer
  },
  {
    name: 'SmsLanding',
    path: '/store/sms-landing',
    component: SmsLandingContainer
  },
  {
    name: 'NewbieHome',
    path: '/store/activity/newbie',
    component: NewbieHomeContainer
  }
]

const infoPages = [
  {
    name: 'FlamingoInfo',
    path: '/store/info',
    component: InfoContainer
  },
  {
    name: 'Policy',
    path: '/store/policy',
    component: PolicyContainer
  }
]

export default {
  routes: [
    ...corePages,
    ...shoppingPages,
    ...orderPages,
    ...promotionPages,
    ...girlsShowPages,
    ...userPages,
    ...gamesPage,
    ...infoPages
  ],
  NotFound: NotFoundPageContainer
}