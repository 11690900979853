import React from 'react'

import Image from '../../common/Image/Image'
import Link from '../../common/Link/Link'
import QuantityChooser from '../Product/QuantityChooser'
import InventoryTag from './InventoryTag'
import { withPlugin } from '@flamingo_tech/funkgo'
import CartSizePicker from './CartSizePicker'
import FlashDiscount, { FlashIcon } from '../ProductCard/FlashDiscount'
import FlashSaleTag from '../FlashSalePage/FlashSaleTag'
import cx from '../../../utils/className'

import styles from './CartItem.module.css'
const DEFAULT_VARIANT_TITLE = 'Default Title'


const CartItem = props => {
  const { forceMobile = false, needConfirmQuantity = true } = props
  const isDesktop = forceMobile ? false : props.$detector.isDesktop()
  const { variantPrice, variantMsrp } = props
  const hasOriginalPrice = variantMsrp && variantMsrp > variantPrice


  const onQuiatityChange = quantity => {
    if (quantity === 0 && needConfirmQuantity) {
      props.$confirm({
        title: props.$i18n.transl('core.cart.sureDelete'),
        confirmLabel: props.$i18n.transl('common.delete'),
        cancelLabel: props.$i18n.transl('common.cancel')
      }).then(isConfirm => {
        if (isConfirm) {
          props.onChange(quantity)
        }
      })
      return
    }
    props.onChange(quantity)
  }

  return (
    <div className={cx(styles.wrapper, !props.saleable && styles.disabled, isDesktop && styles.isDesktop, props.className)}>
      {
        props.isFlashSale && (
          <FlashDiscount
            small
            isDesktop={isDesktop}
            price={props.variantPrice}
            msrp={props.variantMsrp}
          />
        )
      }
      {
        !props.saleable && <div className={styles.cover} />
      }
      <Link className={styles.imageContainer} to={props.to || { name: 'Product', params: { handle: props.handle }}}>
        <Image objectFit='cover' src={props.image} className={styles.image} small crop={{ width: 1, height: 1 }} placeholder={{ width: 1, height: 1 }} />
        {
          props.discountTag && <div className={styles.discountTag}>{props.discountTag}</div>
        }
        { props.saleable && <InventoryTag inventory={props.inventory} /> }
      </Link>
      <div className={styles.rightWrapper}>
        <div className={cx(styles.name, props.nameClass)}>{props.title}</div>
        <CartSizePicker
          colorSwatch={props.colorSwatch}
          selectedOptions={props.selectedOptions}
          product={props}
          isDesktop={isDesktop}
          value={props.variantTitle !== DEFAULT_VARIANT_TITLE ? props.variantTitle : ''}
          colorPickerImageClass={props.colorPickerImageClass}
          isUpdateMode={true}
        />
        <div className={styles.priceAndQuality}>
          <div className={cx(styles.priceRow, props.className)}>
            {props.isFlashSale && <FlashIcon isDesktop={isDesktop} className={styles.cartItemFlashIcon} />}
            <div className={cx(styles.price, hasOriginalPrice && styles.highlighted)}>{props.displayPrice}</div>
            {
              hasOriginalPrice
                ? <span className={styles.originalPrice}>{props.displayMsrp}</span>
                : null
            }
          </div>
          {
            props.saleable &&
            <div className={styles.quantity}>
              <QuantityChooser isDesktop={isDesktop} quantity={props.quantity} onChange={onQuiatityChange} minQuantity={0} />
            </div>
          }
        </div>
        {
          props.isFlashSale && (
            <div className={styles.flashSaleTagBox}>
              <div className={styles.tagBox}>
                <FlashSaleTag isLink={true} promotionActivities={props.promotionActivities} />
              </div>
              {
                !props.canReturn && (
                  <div className={styles.returnTips}>{props.$i18n.transl('core.cart.returnTips')}</div>
                )
              }
            </div>
          )
        }
      </div>
    </div>
  )
}

export default withPlugin(CartItem)