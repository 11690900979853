import React, { useState, useEffect } from 'react'
import Button from '../../common/Button/Button'

import { OutApp } from '../../../hooks/InApp'
import withCartAndPlugins from '../../../hooks/withCartAndPlugins'

import cx from '../../../utils/className'

import styles from './ProductFooterBar.module.css'

const ProductFooterBar = props => {
  const { renderWishButton } = props
  const [shake, setShake] = useState(false)

  useEffect(() => {
    let timer
    if (props.showAddedToCartAnimation) {
      setShake(true)
      timer = setTimeout(() => {
        setShake(false)
      }, 300)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [props])

  const renderButtonElement = () => {
    if (typeof props.renderButton === 'function') {
      return props.renderButton()
    }

    if (props.isUpdateMode) {
      return (
        <Button
          onClick={props.onAddToCartClick}
          className={styles.addToCartBtn}
        >{props.$i18n.transl('common.update')}</Button>
      )
    } else {
      return (
        <Button
          onClick={props.onAddToCartClick}
          className={cx(styles.addToCartBtn, !props.availableForSale && styles.reminderStyle)}
        >{props.availableForSale ? props.$i18n.transl('core.product.addToCart') : props.$i18n.transl('core.product.outOfStock')}</Button>
      )
    }
  }

  return (
    <div className={cx(styles.wrapper, props.className)}>
      {
        props.showCartButton && (
          <div className={cx(styles.cartIcon, shake && styles.shake)} onClick={props.handleToCart}>
            <span>&#xe61c;</span>
            {
              props.cartLineItemCount > 0 && <div className={styles.cartCounts}>{props.cartLineItemCount}</div>
            }
          </div>
        )
      }
      <OutApp>
        {
          renderWishButton && (
            <div className={styles.wishButton}>{props.renderWishButton()}</div>
          )
        }
      </OutApp>
      {renderButtonElement()}
    </div>
  )
}

export default withCartAndPlugins(ProductFooterBar)
