
import CouponModel from '../../plugins/StorePlugin/models/CouponModel'
import OrderModel from './OrderModel'
import UserAddressModel from '../common/UserAddressModel'
import { parsePrice } from '@flamingo_tech/funkgo/src/utils/price'

class AnonymousUserModel {
  constructor(options) {
    Object.assign(this, {
      accessToken: options.accessToken,
      id: options.userId || options.id,
      email: options.email,
      name: options.userName || options.name,
      activeFlag: options.activeFlag
    })
  }
}

class LineItemModel {
  constructor(data) {
    Object.assign(this, {
      ...data,
      price: parsePrice(data.price),
      msrp: data.msrp && parsePrice(data.msrp)
    })
  }
}


class DraftOrderModel {
  constructor(data) {
    Object.assign(this, {
      orderId: data.orderId,
      bizType: data.bizType,
      draftFlag: data.draftFlag,
      payStatus: data.payStatus,

      ...Fee(data),
      willObtainCoins: data.willObtainCoins,
      obtainCoins: data.obtainCoins,
      userCoin: data.userCoin,
      coinsChecked: data.coinsChecked,
      currencyCode: data.currencyCode,
      currencySymbol: data.currencySymbol,

      list: data.list.map(item => new LineItemModel(item)),


      anonymousUser: data.anonymousUserInfo ? new AnonymousUserModel({
        ...data.anonymousUserInfo.userInfo,
      }) : null,

      couponList: data.couponList ? data.couponList.map(item => {
        const coupon = CouponModel.transferBE2FECouponModel(item)
        coupon.discountInfo = item.discountInfo || {}

        return coupon
      }): [],
      couponId: data.couponId,

      model: new OrderModel(data), //TODO: for track plugin, need update

      orderShippingAddress: data.shippingAddress ? new UserAddressModel(data.shippingAddress) : null,

      orderItemChangeList: data.orderItemChangeList || [],

      activityRewardRule: data.activityRewardRule || {}
    })
  }
}



class CalcAmountModel {
  constructor(data) {
    const couponList = data.couponList ? data.couponList.map(item => {
      const coupon = CouponModel.transferBE2FECouponModel(item)
      coupon.discountInfo = item.discountInfo || {}

      return coupon
    }): []

    let selectedCoupon
    if (couponList[0] && couponList[0].discountInfo.fit ) {
      selectedCoupon = couponList[0]
    }

    Object.assign(this, {
      ...Fee(data),
      willObtainCoins: data.willObtainCoins,
      obtainCoins: data.obtainCoins,
      userCoin: data.userCoin,
      coinsChecked: data.coinsChecked,

      couponList,
      selectedCoupon
    })
  }
}


const Fee = data  => {
  return {
    productFee: data.productFee,
    discountFee: data.discountFee,
    coinsFee: data.coinsFee,
    totalDiscounts: data.totalDiscounts,
    freight: data.freight,
    totalFee: data.totalFee,

    displayCoinsFee: data.displayCoinsFee,
    displayDiscountFee: data.displayDiscountFee,
    displayFreight: data.displayFreight,
    displayProductFee: data.displayProductFee,
    displayTotalDiscounts: data.displayTotalDiscounts,
    displayTotalFee: data.displayTotalFee,
    email: data.email,
    totalQuantity: data.totalQuantity
  }
}

export default DraftOrderModel

export {
  CalcAmountModel
}