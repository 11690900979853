import React from 'react'

import styles from './CartSizePicker.module.css'
import cx from '../../../utils/className'

import ColorPicker from '../../common/ColorPicker/ColorPicker'

import withQuickAdd from '../../../hooks/withQuickAdd'
import withCartAndPlugins from '../../../hooks/withCartAndPlugins'


const CartSizePicker = props => {
  const { selectedOptions = [] } = props.product || {}
  const originSelectedVariant = {}

  selectedOptions.forEach(item => {
    originSelectedVariant[item.name] = item.value
  })

  const handleQuickAdd = () => {
    if (props.disabled) {
      return
    }
    props.onQuickAdd({
      product: props.product,
      selectedVariantInfo: originSelectedVariant
    })
  }

  const { value } = selectedOptions[1]

  return (
    <div
      className={cx(styles.sizePicker, props.isDesktop && styles.pc, props.className)}
      onClick={handleQuickAdd}
    >
      {
        Object.keys(props.colorSwatch).length === 0
          ? <span className={styles.sizeValue}>{props.value}</span>
          : (
            <>
              <ColorPicker active={false} colorSwatchItem={props.colorSwatch} imageClass={cx(styles.imageClass, props.colorPickerImageClass)} className={styles.colorPicker} />
              <span className={styles.sizeValue}>/ {value}</span>
            </>
          )
      }
      {
        !props.disabled && <span className={styles.sizePickerIcon}>&#xe7b2;</span>
      }
    </div>
  )
}

export default withQuickAdd(withCartAndPlugins(CartSizePicker))